.hover_link {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  letter-spacing: 1.5px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.photo-gris {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}
.hover_link a {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  border-bottom: none;
  transition: 5s ease-in-out !important;
  font-weight: 500;
}
.plus_info {
  color: #fff;
  letter-spacing: 1.2px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.item {
  min-height: 5rem;
  min-width: 250px;
  padding: 20px;
}
.step {
  width: 200px;
}
.item p {
  font-size: 0.9rem;
}
.item img {
  height: 80px;
  /* width: 100px;
  
  border-radius: 0.2rem;
  pointer-events: none; */
}
.inner-carousel {
  display: flex;
}
.carousel {
  cursor: grab;
  overflow: hidden;
}
.hover_link a::before {
  content: attr(data-text);
  width: 250px;
  overflow: hidden;
  position: absolute;
  color: red;
  white-space: nowrap;
}
