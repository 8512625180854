.floating-cart {
    position: fixed;
    top: 5px;
    right: 10px;
    padding: 10px 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #222;
    z-index: 200;
}

.img-notif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.img-notif-container .icone1 {
    width: 20px;
}

.img-notif-container .icone2 {
    width: 30px;
}
.img-notif-container .notif {
    position: absolute;
    top: -4px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #c19d56;
    color: #fff;
    border: 1px solid #c19d56;
    font-weight: 600;
    font-size: 12px;
}