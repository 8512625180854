.tool-bar {
  background-color: #fff;
  color: #222f3e;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid gray;
}

.gg {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.sidebar {
  position: fixed;
  background-color: #fff;
  color: #fff;
  top: 0;
  left: 0;
  height: 100vh;
  width: 280px;
  transform: translateX(-100%);
  transition: all 0.4s;
  z-index: 100;
  padding-top: 4rem;
}
.sidebar .linkLi {
  outline: none;
  text-decoration: none;
  list-style: none;
  padding: 1rem 2rem 0.5rem 0;
  margin: 0 1rem;
  border-bottom: solid #c19d56 1px;
  color: #222f3e;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 300;
}
.sidebar .linkLi:hover {
  color: #2128f5;
}
.backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: none;
  z-index: 99;
}
.sidebar--open {
  transform: translateX(0%);
}
.backdrop--open {
  display: block;
  z-index: 99;
}
