*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Light";
  src: local("font"),
    url("./fonts/font.woff") format("truetype");
}

@font-face {
  font-family: "texte";
  src: local("font55"),
    url("./fonts/font55.woff") format("truetype");
}

@font-face {
  font-family: "titre";
  src: local("font85"),
    url("./fonts/font85.woff") format("truetype");
}

body {
  font-family: "texte" !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  box-shadow: none !important
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.form-control,
.form-control:hover,
.form-control:focus,
.form-control:active {
  box-shadow: none !important;
  outline: none !important;
}

.table>:not(caption)>*>* {
  padding: 0.4rem 0.4rem !important;
  font-size: 15px !important;
}

.bg-input {
  background: rgb(248, 248, 248) !important;
}

.text-black-center {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  text-align: center;
  font-size: 13px !important;
}

.text-apply-center {
  background-color: rgb(0 0 0);
  color: #fff !important;
  text-align: center;
  height: 2.2rem;
  font-size: 14px !important;
  border-radius: 3px;
}

.apply-ok {
  width: 300px;
  margin: auto;
  padding: 0.1rem;
  color: white !important;
}

.apply-ko {
  background-color: rgb(0 0 0);
  width: 300px;
  margin: auto;
  padding: 0.4rem;
  color: white !important;
}

.text-apply-center-disable {
  background-color: #36373657;
  color: #fff !important;
  text-align: center;
  height: 2.2rem;
  font-size: 12px !important;
  border-radius: 3px;
}

.btn-submit {
  background: #c19d56;
  border: 1px solid #c19d56;
  font-weight: 600;
}

.btn-submit-disable {
  background: #c19d5678 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
 
.container-paypal {    
  width: 100%;
  margin: auto;
  align-content: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 19px;
  margin-top: -100px;
}

.paypal-buttons{
  position: relative;
  display: inline-block; 
  width: 300px  !important;;
  font-size: 0;
  margin: auto !important;
}

.px-5 { 
  padding-top: 10px;
}

.div-valid {
  width: 260px;
  margin: auto;
  display: flex; 
  justify-content: center;
}

.btn-valid {
  border: none;
  border-radius: 23px  !important;;
  color: #f4ecec !important;
  background: #000000de;
  cursor: pointer;
  padding: 10px 40px 10px 40px !important;
  font-family: PayPalOpen-Regular, Helvetica, Arial, "Liberation Sans", sans-serif !important;
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;
}

span.info-billing {
  font-weight: bold;
  margin-top: 15px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000; /* Change color as needed */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}  

.customPadding{
  padding-top: 7px !important;
}

.scrollHeight{
  max-height: 311px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}
 
.fixed-footer{
  height: 120px;
  background: #f9f9f9 !important;
}