/* New styles for the tabs */
.tabsWrapper {
  max-height: 60px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  position: relative;
  z-index: 1000;
}

.tabsWrapper::-webkit-scrollbar {
  width: 0;
  display: none;
}

.tabItem {
  min-width: 150px;
  text-align: center;
  margin-right: 2px;
  text-decoration: none;
  color: #c19d56;
  border-radius: 999px;
  font-weight: 500;
  transition: all 0.3s;
  background: #fff;
}

.tabItem.active {
  background: #000;
  color: #fff;
}

.t {
  background: #fff;
  position: sticky;
  top: 56px;
}

.col {
  background: #f8f8f8;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.productTitle {
  font-weight: 600;
  font-family: "titre";
}

.productMenuTitle {
  font-weight: 600;
}

.productDescription {
  font-family: "Light";
}

.productPrice {
  color: #a5a5a5;
}

.productImage {
  height: 90px;
  max-width: 100%;
}

.fixed-cart {
  height: 90px;
  display: grid;
  gap: 2px;
  margin: 3px;
  background: #fff;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
}

.cart-button {
  background: #c19d56;
  border: 1px solid #c19d56;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  padding: 6px 12px;
}

.cart-count {
  background: #61481c;
  border: 1px solid #61481c;
  border-radius: 999px;
  font-weight: 600;
  padding: 4px 8px;
  margin-right: 8px;
}

.cart-total {
  font-weight: 600;
}
