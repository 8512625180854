
.form-check-label {
    margin-left: 10px;
  }
  
  .form-control {
    position: relative;
    padding-right: 40px; /* To accommodate the password toggle icon */
  }
  
  .position-absolute {
    cursor: pointer;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
  
  .btn-block {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
  
  