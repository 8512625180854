.read-more {
    text-align: justify;
    font-family: "texte" !important;
}
.btn-more {
    background-color: transparent;
    border: none;
    font-weight: 500;
    color: rgb(139, 17, 209);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle {
    background-color: #E8E8E8;
    margin: 30px 3px 0px 3px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.test{
    position: relative;
}
.box1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
}

