

  .bttn:focus {
    outline: none;
  }
  .choixXX:focus {
    outline: none !important;
  }
  .choixXX:active {
    outline: none !important;
  }
  .choixXX:hover {
    outline: none !important;
  }
  .choixXX {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #999 !important;
    transition: 0.2s all linear;
  }
  
   .choixXX:checked {
    border: 5px solid #1c2d59 !important;
    outline: unset !important; /* I added this one for Edge (chromium) support */
    box-shadow: none;
    background: transparent;
  } 

  .account-container {
    margin-top: 5em;
    display: block;
    min-height: 100vh;
  }
  
  .heading {
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .form-container {
    background-color: #f8f9fa;
    padding: 0.5em;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  