/* slideshow.css */
.slideshow {
  width: 80%;
  max-width: 1200px; /* Limit the maximum width of the slideshow */
  margin: 0 auto;
  overflow: hidden;
}

.slide-image {
  width: 100%;
  height: auto; /* Allow the image to scale based on its aspect ratio */
  max-height: 80vh; /* Limit the height for larger screens */
  object-fit: cover;
  object-position: center top;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Media query for larger screens */
@media screen and (min-width: 1200px) {
  .slide-image {
    max-height: 60vh; /* Reduce the height for bigger screens */
  }
}

/* Media query for larger screens */
@media screen and (min-width: 1200px) {
  .slide-image {
    height: 80vh; /* Increase the height for bigger screens */
  }
}

/* Optional: Add some transition effects for smoother slide transitions */
.slick-slide {
  transition: opacity 0.5s ease-in-out;
}
.slick-slide.slick-active {
  opacity: 1;
}
.slick-slide:not(.slick-active) {
  opacity: 0.5;
}
