/* Footer Container */
.footer-container {
    background: linear-gradient(135deg, #005cbf, #0088bf);
    padding: 20px;
    text-align: center;
    color: #fff;
  }
  
  /* Footer Links */
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    margin-right: 15px;
  }
  
  .footer-links a:last-child {
    margin-right: 0;
  }
  
  /* Footer Social Icons */
  .footer-social a {
    display: inline-block;
    margin-right: 15px;
    color: #c19d56;
    text-decoration: none;
  }
  
  .footer-social a:last-child {
    margin-right: 0;
  }
  
  /* Screen reader-only text */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  