.wrapperrr{
    display: flex;
    /* height: 100vh; */
    width: 100%;
    justify-content: center;
    align-items: center;
}
.accordionnn{
    width: 500px;
    padding-top:100px;
    padding-bottom: 30px;
}
.itemmm {
    background: #f0ebe1;
    margin-bottom: 5px;
    padding: 10px 20px;
}
.titleee {
    color: #85662b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.contenttt {
    color: #8b7f75;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}
.contenttt.showww{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}