/* Floating profile container */
.floating-profile-container {
    position: fixed;
    top: 5px;
    right: 40px;
    padding: 10px 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #222;
    z-index: 200;
  }
  
  /* Profile icon container */
  .profile-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  /* Profile icon styles */
  .profile-icon-container .profile-icon {
    width: 18px;
    margin-right: 5px;
  }
  
  /* Notification styles */
  .profile-icon-container .notification-badge {
    position: absolute;
    top: -4px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #c19d56;
    color: #fff;
    border: 1px solid #c19d56;
    font-weight: 600;
    font-size: 12px;
  }
  