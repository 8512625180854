.choixX {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #999;
  transition: 0.2s all linear;
  margin-top: 6px;
}

.choixX:checked {
  border: 5px solid #1c2d59;
  outline: none;
}

.backAvt {
  padding: 6px;
  position: absolute;
  top: 60px;
  left: 20px;
  padding-top: 2px;
  padding-left: 3px;
  padding-right: 6px;
}

.row.justify-content-between.py-1.mx-3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

label.col-auto.mr-auto.p-0 {
  font-weight: bold;
}

.btn-primary {
  font-weight: 600;
  transition: all 0.5s;
}

.btn-primary.py-2.mx-3 {
  margin-right: 20px;
}

.btn-primary[disabled] {
  background-color: grey;
  border: 1px solid grey;
  opacity: 0.3;
  cursor: not-allowed;
}

.btn-primary:not([disabled]) {
  background-color: #c19d56;
  border: 1px solid #c19d56;
  cursor: pointer;
}
